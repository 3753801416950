import { oss_svg_image_domain_address } from '@/constants/oss'
import { useCommonStore } from '@/store/common'
import { t } from '@lingui/macro'
import { useEffect, useMemo, useState } from 'react'
import { businessId, envIsCommon } from '@/helper/env'
import classNames from 'classnames'
import { getDownloadApiData } from '@/apis/download'
import styles from './index.module.css'
import { WalletComponent } from '../wallet/wallet-component'

function HomePage() {
  const { locale } = useCommonStore()
  const localePath = useMemo(() => {
    switch (locale) {
      case 'en-US':
        return 'IM-chat-en'
      case 'zh-HK':
        return 'IM-chat-hk'
      default:
        return 'IM-chat-cn'
    }
  }, [locale])
  const [appInfo, setAppInfo] = useState<any>([])
  const homeUrl = `${oss_svg_image_domain_address}IM-chat-cn/home/`
  const homeUrlWithLocale = `${oss_svg_image_domain_address}${localePath}/home/`
  let imgUSorCNPro = `${homeUrlWithLocale}${envIsCommon ? 'iPhone_14_Pro.png' : 'iPhone_14_Pro_pu.png'}`
  let imgGongZuo05 = `${homeUrlWithLocale}${
    envIsCommon ? 'icon_index_gongzuo05_new.png' : 'icon_index_gongzuo05_pu.png'
  }`
  let imgGouTong03 = `${homeUrlWithLocale}${
    envIsCommon ? 'icon_index_goutong03_new.png' : 'icon_index_goutong03_pu.png'
  }`
  let imgGouTong04 = `${homeUrlWithLocale}${
    envIsCommon ? 'icon_index_goutong04_new.png' : 'icon_index_goutong04_pu.png'
  }`
  const getDownload = async () => {
    const res = await getDownloadApiData({ businessId, lanType: locale })
    const { isOk, data } = res || {}
    if (isOk && data) {
      setAppInfo(data)
    }
  }
  useEffect(() => {
    getDownload()
  }, [])

  // en-US
  return (
    <div className={styles.scoped}>
      <div className={classNames('home-header-bg', envIsCommon ? 'home-header-bg-new' : 'home-header-bg-pu')}>
        <div className="im-text-introduce">
          <div className="text-center">{t`features_home_page_index_ggeqoq0676`}</div>
          <div className="introduce1">{t`features_home_page_index_30k90z8phg`}</div>
        </div>
        <WalletComponent h5 appInfo={appInfo} />

        <div className="introduce-content">
          <div className="introduce-content-left">
            <div className="left-top">
              <img className="avatar" src={`${homeUrl}defaultheadurl.png`} alt="" />
              <div className="state">
                <div className="character">
                  <span className="character-name">{t`features_home_page_index_1byhmdbmky`}</span>
                  <span className="character-time">13:43</span>
                </div>
                <div className="view-state">
                  <div>
                    <img src={`${homeUrl}icon_index_banner01.svg`} alt="" />
                  </div>
                  <span>{t`features_home_page_index_o1jkln5uje`}</span>
                </div>
              </div>
            </div>
            <div className="left-center">
              <div className="left-center-name">{t`features_home_page_index_dgnaqsop26`}</div>
              <div className="left-center-time">00:00</div>
              <div className="tips-ico">
                <div className="turn-accept">
                  <div className="mb-1">
                    <img src={`${homeUrl}icon_index_banner02.svg`} alt="" />
                  </div>
                  <div className="turn-text">{t`features_home_page_index_vysumxouxt`}</div>
                </div>
                <div className="turn-accept accept">
                  <div className="mb-1">
                    <img src={`${homeUrl}icon_index_banner10.svg`} alt="" />
                  </div>
                  <div className="turn-text">{t`features_home_page_index_oowgpfntuv`}</div>
                </div>
              </div>
            </div>
            <div className="left-foot left-top">
              <div className="avatar">
                <img
                  src={`${homeUrl}${envIsCommon ? 'icon_index_banner03_new.svg' : 'icon_index_banner03_pu.svg'}`}
                  alt=""
                />
              </div>
              <div className="state">
                <div className="character">
                  <span className="character-name">{t`features_home_page_index_rnyb8l02kx`}</span>
                  <span className="character-time">13:43</span>
                </div>
                <div className="view-state">
                  <span>{t`features_home_page_index_fwlzkdnais`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="introduce-content-center">
            <img src={imgUSorCNPro} alt="" />
          </div>
          <div className="introduce-content-right">
            <div className="right-top">
              <img src={`${homeUrl}icon_index_banner04.svg`} alt="" />
            </div>
            <div className="right-center">
              <div className="right-center-box">
                <div className="flex">
                  <div className="mr-2">
                    <img src={`${homeUrl}icon_index_banner05.svg`} alt="" />
                  </div>
                  <div className="right-center-text">{t`features_home_page_index_qbe59yk_rt`}</div>
                </div>
                <div>
                  <img
                    src={`${homeUrl}${envIsCommon ? 'icon_index_banner09_new.svg' : 'icon_index_banner09_pu.svg'}`}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="right-foot">
              <img src={`${homeUrl}icon_index_hei.png`} alt="" width={376} height={277} />
            </div>
          </div>
        </div>

        {/* h5 部分 */}
        <div className="h5-home-header">
          <div className="left-top">
            <div className="avatar">
              Z<div className="small-dot"></div>
            </div>
            <div className="state">
              <div className="character">
                <span className="character-name">{t`features_home_page_index_1byhmdbmky`}</span>
                <span className="character-time">13:43</span>
              </div>
              <div className="view-state">
                <div>
                  <img src={`${homeUrl}icon_index_banner01.svg`} alt="" />
                </div>
                <span>{t`features_home_page_index_o1jkln5uje`}</span>
              </div>
            </div>
          </div>
          <div className="left-center">
            <div className="left-center-name">{t`features_home_page_index_dgnaqsop26`}</div>
            <div className="left-center-time">00:00</div>
            <div className="tips-ico">
              <div className="turn-accept">
                <div className="mb-1">
                  <img src={`${homeUrl}icon_index_banner02.svg`} alt="" />
                </div>
                <div className="turn-text">{t`features_home_page_index_vysumxouxt`}</div>
              </div>
              <div className="turn-accept accept">
                <div className="mb-1">
                  <img src={`${homeUrl}icon_index_banner10.svg`} alt="" />
                </div>
                <div className="turn-text">{t`features_home_page_index_oowgpfntuv`}</div>
              </div>
            </div>
          </div>
          <div className="right-center">
            <div className="right-center-box">
              <div className="flex">
                <div className="mr-2">
                  <img src={`${homeUrl}icon_index_banner05.svg`} alt="" />
                </div>
                <div className="right-center-text">{t`features_home_page_index_qbe59yk_rt`}</div>
              </div>
              <div>
                <img src={`${homeUrl}icon_index_banner09.svg`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="secure communal">
        <div className="contant-auto">
          <div className="img-introduce">
            <img src={`${homeUrl}icon_index_anquan02.png`} alt="" />
            <div className={classNames('dialogue', envIsCommon ? 'dialogue-new' : 'dialogue-pu')}>
              <div className="dialogue-name-box">
                <div
                  className={classNames('dialogue-name', envIsCommon ? 'dialogue-name-new' : 'dialogue-name-pu')}
                >{t`features_home_page_index_3o8ixcoako`}</div>
              </div>
              <div className="dialog-box-text">
                <img src={`${homeUrl}Group 209.svg`} alt="" />
                <div className="dialog-text">{t`features_home_page_index_wxihrtbktb`}</div>
              </div>
              <div className="dialog-foot">
                <img
                  src={`${homeUrl}${envIsCommon ? 'icon_index_anquan07_new.svg' : 'icon_index_anquan07_pu.svg'}`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="text-introduce">
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_anquan01_new.svg' : 'icon_index_anquan01_pu.svg'}`}
              alt=""
            />
            <div className="text-titel">{t`features_about_us_index_cw_rurhehr`}</div>
            <div className="text-contant">{t`features_home_page_index_6po3i5mm_p`}</div>
          </div>
        </div>
      </div>
      <div className="efficiency communal">
        <div className="contant-auto">
          <div className="text-introduce">
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_gongzuo01_new.svg' : 'icon_index_gongzuo01_pu.svg'}`}
              alt=""
            />

            <div className="text-titel">{t`features_about_us_index_j3zgfbsnto`}</div>
            <div className="text-contant">{t`features_home_page_index_6uoxu2ejob`}</div>
          </div>
          <div className="img-introduce">
            <img src={imgGongZuo05} alt="" />
            <img src={`${homeUrl}icon_index_gongzuo02.svg`} alt="" className="small1 small-w" />
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_gongzuo04.svg' : 'icon_index_gongzuo04_pu.svg'}`}
              alt=""
              className="small2 small-w"
            />
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_gongzuo03_new.svg' : 'icon_index_gongzuo03_pu.svg'}`}
              alt=""
              className="small3 small-w"
            />
          </div>
        </div>
      </div>
      <div className="communication communal">
        <div className="contant-auto">
          <div className="img-introduce">
            <img src={imgGouTong03} alt="" />
            <img src={imgGouTong04} alt="" className="goutong04" />
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_goutong02_new.svg' : 'icon_index_goutong02_pu.svg'}`}
              alt=""
              className="goutong02"
            />
          </div>
          <div className="text-introduce">
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_goutong01_new.svg' : 'icon_index_goutong01_pu.svg'}`}
              alt=""
            />
            <div className="text-titel">{t`features_home_page_index_wyuv95vnhu`}</div>
            <div className="text-contant">{t`features_home_page_index_5kaif9tui2`}</div>
          </div>
        </div>
      </div>
      <div className="video-call communal">
        <div className="contant-auto">
          <div className="text-introduce">
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_gaoqing01_new.svg' : 'icon_index_gaoqing01_pu.svg'}`}
              alt=""
            />
            <div className="text-titel">{t`features_home_page_index_mei4puomxo`}</div>
            <div className="text-contant">{t`features_home_page_index_brvorm3e8x`}</div>
          </div>
          <div className="img-introduce">
            <img
              src={`${homeUrl}${envIsCommon ? 'icon_index_gaoqing02.svg' : 'icon_index_gaoqing02_pu.png'}`}
              alt=""
              className="video-gaoqing02"
            />
            <img src={`${homeUrl}icon_index_gaoqing05.png`} alt="" className="gaoqing05" />
            <img src={`${homeUrl}icon_index_gaoqing06.png`} alt="" className="gaoqing06" />
            <img src={`${homeUrl}icon_index_gaoqing03.png`} alt="" className="gaoqing03" />
            <img
              src={`${homeUrl}${envIsCommon ? 'im-log-new.svg' : 'icon_index_gaoqing04_pu.svg'}`}
              alt=""
              className="im-log"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage
